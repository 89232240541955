<template>
  <div>
    {{ $t(title) }}:
    <span
      :class="
        paymentAllStatus.isPaid || isCashOnDelivery
          ? 'provider-font-color'
          : paymentAllStatus.isPaid == null
          ? ''
          : 'red--text'
      "
    >
      <template v-if="orderData.payment_type">
        {{ $t(`data.payment_type.${orderData.payment_type}`) }}
      </template>
      <template v-if="!isCashOnDelivery && paymentAllStatus.isUnpaid !== null">
        {{ paymentStatusText }}
      </template>
    </span>
  </div>
</template>
<script>
import paymentTypeForB2C from "@/components/payment/mixins/paymentTypeForB2C.js";
// UNPAY: 未付款, PAID: 已付款, REFUND: 已退款, FAIL: 付款失敗
export default {
  mixins: [paymentTypeForB2C],
  props: {
    title: {
      type: String,
      default: "data.payment_status",
    },
    orderData: {
      type: Object,
      default: null,
    },
    refundCompleted: {
      type: Boolean,
      default: false,
    },
    catchPaymentAllStatus: {
      type: Function,
    },
  },
  data: () => ({
    paymentResult: {},
  }),
  computed: {
    isCashOnDelivery() {
      return this.orderData
        ? this.orderData.payment_type == "cash_on_delivery"
        : false;
    },
    paymentAllStatus() {
      const status = this.paymentResult.payment_status;
      return {
        isRefund: status ? status == "REFUND" : null,
        isPaid: status ? status == "PAID" : null,
        isUnpaid: status ? status == "UNPAY" : null,
      };
    },
    paymentStatusText() {
      return (
        " - " +
        this.$t(
          `data.payment_status.${this.paymentResult.payment_status.toLowerCase()}`
        )
      );
    },
  },
  watch: {
    paymentAllStatus: {
      immediate: true,
      deep: true,
      handler() {
        this.getPaymentAllStatus();
      },
    },
    refundCompleted(newVal) {
      if (newVal) this.checkPaymentResult();
    },
    orderData: {
      deep: true,
      handler() {
        this.checkPaymentResult();
      },
    },
  },
  methods: {
    getPaymentAllStatus() {
      if (typeof this.catchPaymentAllStatus != "function") return;
      this.catchPaymentAllStatus(this.paymentAllStatus);
    },
    async checkPaymentResult() {
      console.log('checkPaymentResult:', this.orderData.payment_record_id)
      if (this.orderData.payment_record_id == 0) {
        this.paymentResult = {
          payment_status: this.orderData.payment_status
        };
        return;
      }
      try {
        await this.$api.collection.orderApi
          .paymentResult(this.orderData.payment_record_id)
          .then((res) => {
            this.paymentResult = res;
          });
      } catch (error) {
        console.error(error);
        this.$snotify.error("訂單付款結果查詢異常");
      }
    },
  },
  mounted() {
    this.checkPaymentResult();
  },
};
</script>
<style lang=""></style>
