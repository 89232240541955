<template>
  <div class="py-3 ">
    <div class="d-flex justify-space-between align-end" v-if="data">
      <div>
        <div class="pb-3">
          {{ data.store_name }} --
          <span :class="statusType">{{ $t(`order.status.${data.status}`) }}</span>
        </div>
        <div class="pb-3">訂單編號: {{ order_no }}</div>
        <div class="pb-3">{{ $helper.orderTimeFormat(data.created_at) }}</div>
        <div class="pb-3 d-flex align-center">
          <paymentStatus
            ref="paymentStatus"
            :orderData="data"
            :refundCompleted="refundCompleted"
            :catchPaymentAllStatus="catchPaymentAllStatus"
          ></paymentStatus>
          <paymentStatusChangeToRefundBtn
            v-if="
              isThirdPartyPaymentType &&
                paymentAllStatus.isPaid &&
                (isCanceled || isReject)
            "
            class="ml-4"
            :orderData="data"
          ></paymentStatusChangeToRefundBtn>
        </div>
      </div>
      <div class="pb-5">
        <v-btn
          depressed
          dark
          color="primary"
          :to="{ name: 'client-info', params: { storeId: data.store_info.id } }"
        >
          客戶資訊
        </v-btn>
      </div>
    </div>
    <div class="d-flex" v-if="data && data.transNo && data.payment_type === 'pay_first'">
      匯款帳號末五碼: {{ data.transNo }}
    </div>
  </div>
</template>

<script >
import ItemMixin from "@/components/form/custom/ItemMixin.js";
import orderConstants from "@/modules/base/config/orderConstants";

import paymentTypeForB2C from "@/components/payment/mixins/paymentTypeForB2C.js";
import paymentStatus from "@/components/payment/paymentStatus.vue";
import paymentStatusChangeToRefundBtn from "@/components/payment/paymentStatusChangeToRefundBtn.vue";

export default {
  mixins: [ItemMixin, paymentTypeForB2C],
  components: {
    paymentStatus,
    paymentStatusChangeToRefundBtn,
    // formInfo: () => import('@/components/form/custom/formInfo.vue'),
    // bar: () => import('@/modules/base/components/bar/bar.vue'),
  },
  data: () => ({
    data: null,
    orderConstants: orderConstants,
    refundCompleted: false,
    paymentAllStatus: {},
  }),
  computed: {
    order_no() {
      if (!this.data) return null;
      return this.data.order_no;
    },
    orderInfo() {
      if (!this.data) return [];
      return [
        {
          text: "訂單狀態",
          value: this.$t(`order.status.${this.data.status}`),
        },
        {
          text: "訂單時間",
          value: this.$helper.orderTimeFormat(this.data.created_at),
        },
        {
          text: "付款方式",
          value: this.$t(`data.payment_type.${this.data.payment_type}`),
        },
      ];
    },
    storeInfo() {
      if (!this.data) return [];
      if (!this.data.store_info) return [];
      const store_info = this.data.store_info;
      return [
        { text: "客戶", value: this.data.store_name },
        { text: "聯絡人", value: store_info.contact },
        { text: "電話", value: store_info.phone },
        {
          text: "地址",
          value: `${store_info.city}${store_info.district}${store_info.address}`,
        },
        { text: "統一編號", value: store_info.uniform_number },
      ];
    },
    statusType() {
      if (!this.data) return "";
      switch (this.data.status) {
        case "finished":
          return "lighten-text";
        case "reject":
        case "canceled":
          return "red--text";
        case "request_return":
          return "dark-text";
        default:
          return "provider-font-color";
      }
    },
    isCanceled() {
      return this.data.status == "canceled";
    },
    isReject() {
      return this.data.status == "reject";
    },
    isThirdPartyPaymentType() {
      return this.thirdPartyPaymentTypes.includes(this.data.payment_type);
    },
  },
  watch: {
    propsValue: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.propsValue) return;
        this.data = this.$eagleLodash.cloneDeep(this.propsValue);
      },
    },
    data: {
      deep: true,
      handler() {},
    },
  },
  async created() {},
  mounted() {},
  methods: {
    refundIsCompleted() {
      this.refundCompleted = true;
    },
    catchPaymentAllStatus(data) {
      console.log(data);
      this.paymentAllStatus = data;
    },
  },
};
</script>
<style scoped lang="sass">
.lighten-text
  color: #37D21E

.dark-text
  color: #000000
</style>
