<template>
  <div>
    <v-dialog v-model="dialog" width="350">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" depressed dark v-bind="attrs" v-on="on">
          {{ $t(`action.refunded`) }}
        </v-btn>
      </template>
      <v-card class="linerp-layout">
        <v-card-title class="text-h6 justify-center pt-10 pb-6">
          {{ $t(`refund.ask`) }}
        </v-card-title>
        <v-card-actions class="justify-center pb-8">
          <v-btn depressed outlined class="mr-3" @click="dialog = false">
            {{ $t(`action.cancel`) }}
          </v-btn>
          <v-btn color="red" depressed outlined @click="submit()">
            {{ $t(`action.refunded-submit`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["orderData"],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {},
  methods: {
    async submit() {
      this.$store.dispatch("loading/active");
      try {
        await this.$api.collection.providerApi.refundOrder(
          this.orderData.payment_record_id
        );
        this.$parent.refundIsCompleted();
        this.$snotify.success("完成退款");
      } catch (error) {
        console.log(error);
        this.$snotify.error("完成退款功能異常");
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
  },
};
</script>
<style lang=""></style>
